import React, { useState } from 'react';

import MainPhoto1 from '../images/main-photo.jpg';
import MainPhoto2 from '../images/main-photo2.jpg';
import MainPhoto3 from '../images/main-photo3.jpg';
import Siderail1 from '../images/siderail1.jpg';
import Siderail2 from '../images/siderail2.jpg';
import Siderail3 from '../images/siderail3.jpg';
import StyledLink from './link';
import './slider.scss';

export default function Slider() {
  const [photoToShow, setPhoto] = useState(0);
  const photos = [
    { main: MainPhoto1, side: Siderail1 },
    { main: MainPhoto2, side: Siderail2 },
    { main: MainPhoto3, side: Siderail3 },
  ];

  function isSiderailActive(idx) {
    return photoToShow === idx ? 'active' : '';
  }

  return (
    <div className="slider-spacing">
      <div className="slider-container">
        <div className="slider-section">
          <h1>Featured Properties</h1>
          <div className="underline" />
          <div className="slider">
            <div className="photos">
              <div
                style={{
                  backgroundImage: `url(${photos[photoToShow].main})`,
                }}
                className="main-photo"
              />
              <div className="siderail">
                {photos.map((photo, idx) => (
                  <img
                    className={isSiderailActive(idx)}
                    onClick={() => setPhoto(idx)}
                    src={photo.side}
                  />
                ))}
              </div>
            </div>
            <div className="description">
              <h3>Stratford Park Apartment Homes</h3>
              <p>
                Our living community is conveniently located yet secluded enough
                with beautiful river front access so that you can get away from
                your everyday hectic life and relax.
              </p>
              <p>
                28 Sterling Ridge <br /> Dr Delaware, OH 43015
              </p>
              <div className="details">
                <span>80 1 and 2 Bedroom Garden Apartments </span>
                <span>8.1 acres</span>
                <span>Built in 2000</span>
                <span>Bank Permanent Financing</span>
              </div>
              <StyledLink
                external={true}
                linkText="Visit Website"
                to="http://www.stratfordparkapts.com/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
