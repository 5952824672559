import React from 'react';

import Logo from './logo';
import EqualHousing from '../images/equal-housing.png';
import './footer.scss';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="contact">
            <div className="contact-section">Contact Us</div>
            <div className="contact-section bold">
              chris@sciotomanagementgroup.com
            </div>
            <div className="contact-section">
              <div>1225 Dublin Road, Columbus Ohio, 43215</div>
            </div>
            <div className="contact-section">
              <div>Phone: (614) 481-8269</div>
              <div>Fax: (614) 481-8479</div>
            </div>
            <div className="contact-section bold">
              Terms and Conditions <span style={{ margin: '0 5px' }}>•</span>{' '}
              Privacy
            </div>
          </div>
          <div className="sitemap" />
        </div>
        <div className="social-media">
          <div className="images">
            <span>
              <img src={EqualHousing} />
            </span>
            <span>
              <Logo width={85} height={40} />
            </span>
          </div>
          <div>
            © Scioto Management Group {new Date().getFullYear()}. All Rights
            Reserved
          </div>
        </div>
      </div>
    </div>
  );
}
