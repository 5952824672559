import React from 'react';
import { Link } from 'gatsby';

import CircleArrow from './circle-arrow';
import './link.scss';

export default function StyledLink({ linkText, to, external }) {
  return external ? (
    <a className="content-link" href={to} target="_blank">
      {linkText} <CircleArrow />
    </a>
  ) : (
    <Link className="content-link" to={to}>
      {linkText} <CircleArrow />
    </Link>
  );
}
