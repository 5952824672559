import StyledLink from './link';
import React from 'react';

import './section.scss';

export function InnerSection(props) {
  return (
    <div {...props} className="content-container">
      <h1>{props.title}</h1>
      <div className="underline" />
      <div className="text">{props.body}</div>
      {props.linkText && (
        <StyledLink linkText={props.linkText} to={props.linkTo || '/'} />
      )}
    </div>
  );
}

export default function Section(props) {
  return (
    <div
      className={`section bg-image ${
        props.left ? 'left-section' : 'right-section'
      } ${props.backgroundName}`}
    >
      <div className="section-container">
        <InnerSection {...props} />
      </div>
    </div>
  );
}
