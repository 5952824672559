import React from 'react';

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z"
      fill="#A02A14"
    />
    <path
      d="M6.49145 6.34959C6.1359 6.10378 6.04694 5.61629 6.29276 5.26074C6.53857 4.90519 7.02606 4.81623 7.38161 5.06204L11.3824 7.828C11.5026 7.91115 11.5026 8.08889 11.3824 8.17204L7.38161 10.938C7.02606 11.1838 6.53857 11.0948 6.29276 10.7393C6.04694 10.3838 6.1359 9.89625 6.49145 9.65044L8.87867 8.00002L6.49145 6.34959Z"
      fill="white"
    />
  </svg>
);
