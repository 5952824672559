import React from 'react';
import { Link } from 'gatsby';

import Logo from '../logo';

export default class Menu extends React.Component {
  state = {
    isTop: true,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', () => {
      this.setState({
        isTop: window.scrollY <= 10,
      });
    });
  };

  getClassNames = pageName => {
    return this.props.location.pathname === pageName ? 'active-page' : '';
  };

  render() {
    return (
      <>
        <div
          className={`menu-container menu-mobile ${
            this.state.isTop ? '' : 'background'
          }`}
        >
          <div className="menu">
            <div>
              <svg
                width="22"
                height="14"
                viewBox="0 0 22 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.5 1.00299C0.5 1.41721 0.835938 1.75299 1.25 1.75299H20.75C21.1641 1.75299 21.5 1.41721 21.5 1.00299C21.5 0.588776 21.1641 0.252991 20.75 0.252991H1.25C0.835938 0.252991 0.5 0.588776 0.5 1.00299ZM1.25 7.75299C0.835938 7.75299 0.5 7.41721 0.5 7.00299C0.5 6.58878 0.835938 6.25299 1.25 6.25299H20.75C21.1641 6.25299 21.5 6.58878 21.5 7.00299C21.5 7.41721 21.1641 7.75299 20.75 7.75299H1.25ZM1.25 13.753C0.835938 13.753 0.5 13.4172 0.5 13.003C0.5 12.5888 0.835938 12.253 1.25 12.253H20.75C21.1641 12.253 21.5 12.5888 21.5 13.003C21.5 13.4172 21.1641 13.753 20.75 13.753H1.25Z"
                  fill="white"
                />
              </svg>
            </div>
            <a href="tel:16144818269" className="call-us">
              Call Us (614) 481-8269
            </a>
          </div>
        </div>
        <div
          className={`menu-container menu-desktop ${
            this.state.isTop ? '' : 'background'
          }`}
        >
          <Link className="logo" to="/">
            <Logo />
          </Link>
          <div className="menu">
            <Link
              className={this.getClassNames('/communities')}
              to="/communities"
            >
              Our Communities
            </Link>
            <Link
              className={this.getClassNames('/what-we-do')}
              to="/what-we-do"
            >
              What We Do
            </Link>
            <Link
              className={this.getClassNames('/who-we-are')}
              to="/who-we-are"
            >
              Who We Are
            </Link>
            {/*<Link className={this.getClassNames('/portal')} to="/">
              Portal
            </Link>*/}
            <a href="tel:16144818269" className="call-us">
              Call Us (614) 481-8269
            </a>
          </div>
        </div>
      </>
    );
  }
}
